<template>
  <div class="container-fluid">
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h3>Cadastro de cargo</h3>
					</div>
				</div>
			</div>
			<form @submit.prevent="salvar">
				<div class="card-body">
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.cargo.nome.$error }">
								<label for="nome" class="required">Nome</label>
								<input v-model="cargo.nome" type="text" class="form-control" name="nome" id="nome">
								<div class="error-form text-danger" v-if="!$v.cargo.nome.required">Campo obrigatório</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="cbo">CBO</label>
								<input v-model="cargo.cbo" type="text" class="form-control" name="cbo" id="cbo"  placeholder="CBO">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="critico">Crítico?</label>
								<input v-model="cargo.critico" type="number" class="form-control" name="critico" id="critico">
							</div>
						</div>
						<div class="col-md-8 col-sm-12">
							<div class="form-group">
								<label for="descricao" class="required">Descrição</label>
								<textarea class="form-control" v-model="cargo.descricao" rows="3"></textarea>
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-12 mt-5">
							<button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
							<button type="submit" class="btn btn-primary float-right">SALVAR</button>
						</div>
					</div>
				</div>
			</form>
		</div>
  </div>
</template>

<script>
import funcionariosCargosRepository from './../../../services/api/funcionariosCargosRepository'
import { required } from 'vuelidate/lib/validators'
export default {
	validations: {
    cargo: {
			nome: {
				required
			}
		}
  },
	data () {
		return {
			cargo: {
        nome: '',
        descricao: '',
        cbo: null,
        critico: null
      }
		}
	},
	created () {
		if (this.$route.query.id) {
			this.buscaCargo(this.$route.query.id)
		}
	},
	methods: {
		buscaCargo (idCargo) {
			funcionariosCargosRepository.buscaCargo(idCargo).then(response => {
				this.cargo = response.data['data']
			})
		},
		goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'FuncionariosCargos' })
    },
		salvar () {
			this.$v.$touch()
			if (this.$v.$invalid) {
        this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha o formulário corretamente!',
				})
      } else {
				if (this.cargo.id) {
					funcionariosCargosRepository.atualizarCargo(this.cargo.id, this.cargo).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastro atualizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						}
					})
				} else {
					funcionariosCargosRepository.salvarCargo(this.cargo).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cargo cadastrado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						} else {
							this.$swal({
								icon: 'error',
								text: 'Não foi possível realizar o cadastro!'
							}).then( () => {
								this.goBack()
							})
						}
					})
				}
			}
		}
	}
}
</script>

<style>

</style>